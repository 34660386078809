var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c(
                "v-chip-group",
                {
                  staticClass: "flex-grow-1",
                  attrs: {
                    mandatory: "",
                    "show-arrows": "",
                    "active-class": "primary--text",
                  },
                  on: { click: _vm.getExercisesByGroupId },
                  model: {
                    value: _vm.selectedCat,
                    callback: function ($$v) {
                      _vm.selectedCat = $$v
                    },
                    expression: "selectedCat",
                  },
                },
                _vm._l(_vm.categories, function (cat) {
                  return _c("v-chip", { key: cat.id }, [
                    _vm._v(" " + _vm._s(cat.name) + " "),
                  ])
                }),
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    plain: "",
                    tile: "",
                    icon: "",
                    to: `/s/exercises/create`,
                  },
                },
                [
                  _c("iconly", {
                    staticClass: "pointer primary--text w-100 h-100",
                    attrs: { name: "plus", type: "bold" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.items && _vm.items.length > 0
            ? _c(
                "div",
                _vm._l(_vm.items, function (exercise, index) {
                  return _c(
                    "v-card",
                    {
                      key: index,
                      staticClass: "my-2",
                      attrs: { flat: "", hover: "", ripple: "" },
                      on: {
                        click: function ($event) {
                          return _vm.openExerciseBottomSheet(exercise, index)
                        },
                      },
                    },
                    [
                      _c(
                        "b-list-group",
                        [
                          _c(
                            "b-list-group-item",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "v-avatar",
                                {
                                  attrs: { color: "red lighten-5", size: "48" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "red--text text--lighten-1 f18",
                                    },
                                    [_vm._v(_vm._s(index + 1))]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column ms-4 me-0 align-items-start justify-content-center",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "f14 font-weight-bold" },
                                    [_vm._v(_vm._s(exercise.title))]
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ms-auto me-0",
                                  attrs: {
                                    color: exercise.bookmarked
                                      ? "primary"
                                      : "grey darken-4",
                                    icon: "",
                                    outlined: "",
                                    elevation: "0",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      class: exercise.bookmarked
                                        ? "primary--text"
                                        : "grey--text text--darken-4",
                                    },
                                    [_vm._v("mdi-dots-horizontal ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ]),
          _c(
            "infinite-loading",
            {
              attrs: { identifier: _vm.selectedCat },
              on: { infinite: _vm.infiniteHandler },
            },
            [
              _c(
                "div",
                { attrs: { slot: "spinner" }, slot: "spinner" },
                [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-more" }, slot: "no-more" },
                [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-results" }, slot: "no-results" },
                [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("vue-bottom-sheet", { ref: "exerciseBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "v-list",
              { staticClass: "w-100", attrs: { rounded: "", dense: "" } },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center mb-1 d-block f14 font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.selectedExercise.title))]
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseAssign } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "adduser", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Assign to clients")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseBookmark } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.selectedExercise.bookmarked,
                              expression: "!selectedExercise.bookmarked",
                            },
                          ],
                          staticClass: "primary--text",
                          attrs: { name: "bookmark", type: "light" },
                        }),
                        _c("iconly", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedExercise.bookmarked,
                              expression: "selectedExercise.bookmarked",
                            },
                          ],
                          class: "primary--text",
                          attrs: { name: "bookmark", type: "bold" },
                        }),
                      ],
                      1
                    ),
                    !_vm.selectedExercise.bookmarked
                      ? _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Add to favorites")) + " "
                          ),
                        ])
                      : _c("v-list-item-title", { attrs: { tag: "span" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Remove from favorites")) + " "
                          ),
                        ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExercisePreview } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "show", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Preview")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseEdit } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "editsquare", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Edit")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { on: { click: _vm.handleExerciseDelete } },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("iconly", {
                          staticClass: "primary--text",
                          attrs: { name: "delete", type: "light" },
                        }),
                      ],
                      1
                    ),
                    _c("v-list-item-title", { attrs: { tag: "span" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Delete")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-auto mb-2",
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.closeExerciseBottomSheet },
                  },
                  [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("vue-bottom-sheet", { ref: "assignExerciseBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("v-text-field", {
                  staticClass: "w-100 border",
                  attrs: {
                    solo: "",
                    flat: "",
                    label: _vm.$t("Search..."),
                    "prepend-inner-icon": "mdi-magnify",
                    "hide-details": "",
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.querySearchFollowers.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            !_vm.followersError
              ? _c(
                  "v-radio-group",
                  {
                    staticClass: "w-100",
                    attrs: { mandatory: "", color: "primary", column: "" },
                    model: {
                      value: _vm.selectedClient,
                      callback: function ($$v) {
                        _vm.selectedClient = $$v
                      },
                      expression: "selectedClient",
                    },
                  },
                  _vm._l(_vm.followers, function (client, index) {
                    return _c("v-radio", {
                      key: index,
                      staticClass: "ltr",
                      attrs: { ripple: "", value: client },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { staticClass: "rtl" },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            "lazy-src": require("@/assets/images/profile-circle.png"),
                                            src:
                                              client.profilePicture != null &&
                                              client.profilePicture !== ""
                                                ? client.profilePicture
                                                : require("@/assets/images/profile.jpg"),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(
                                              `${client.firstName} ${client.lastName}`
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "my-4" }, [
                  _c("span", [_vm._v(_vm._s(_vm.followersErrorText))]),
                ]),
            _c(
              "div",
              { staticClass: "mt-auto w-100" },
              [
                _c("span", { staticClass: "d-inline-block ms-0 me-2" }, [
                  _vm._v(_vm._s(_vm.$t("Exercise time:"))),
                ]),
                _c("v-otp-input", {
                  staticClass: "d-inline-block w-20",
                  attrs: {
                    length: "1",
                    type: "number",
                    "hide-spin-buttons": "",
                  },
                  model: {
                    value: _vm.availablePeriod,
                    callback: function ($$v) {
                      _vm.availablePeriod = $$v
                    },
                    expression: "availablePeriod",
                  },
                }),
                _c("span", { staticClass: "d-inline-block ms-2 me-0" }, [
                  _vm._v(_vm._s(_vm.$t("Week"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "my-2 w-100" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.submitAssign },
                  },
                  [_vm._v(_vm._s(_vm.$t("Submit")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("vue-bottom-sheet", { ref: "previewExerciseBottomSheet" }, [
        _c(
          "span",
          { staticClass: "text-center mb-1 d-block f14 font-weight-bold" },
          [_vm._v(_vm._s(_vm.selectedExercise.title))]
        ),
        _c("p", {
          staticClass: "text-justify",
          domProps: {
            innerHTML: _vm._s(_vm.selectedExercisePreview.description),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }